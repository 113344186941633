import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";
import { Pages } from "@/store/enums/StoreEnums";
import UserService from "@/core/services/UserService";
import store from "@/store";
import { useRouteStore } from "@/store/pinia/RouteModule";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        meta: { auth: true },
        name: "dashboard",
        component: () => import("@/views/DashboardPage.vue"),
      },
      {
        path: "/categories/:categoryId/items/:itemId",
        meta: { auth: true, group: "category" },
        name: "item.categories.items.show",
        component: () => import("@/views/items/ItemForm.vue"),
      },
      {
        path: "/categories/:categoryId/:type",
        meta: { auth: true, group: "category" },
        name: "item.categories.form",
        component: () => import("@/views/items/CategoryForm.vue"),
      },
      {
        path: "/categories",
        meta: { auth: true, group: "category" },
        name: "item.categories",
        component: () => import("@/views/items/CategoryList.vue"),
      },

      {
        path: "/categories/search",
        meta: { auth: true, group: "category" },
        name: "item.search",
        component: () => import("@/views/items/SearchForm.vue"),
      },
      {
        path: "/categories/:categoryId",
        meta: { auth: true, group: "category" },
        name: "item.categories.detail",
        component: () => import("@/views/items/CategoryList.vue"),
      },
      {
        path: "/skills",
        meta: { auth: true, group: "skills" },
        name: "skills.list",
        component: () => import("@/views/skills/SkillPackList.vue"),
      },
      {
        path: "/territories",
        meta: { auth: true, group: "territory" },
        name: "territories.list",
        component: () => import("@/views/territories/TerritoryList.vue"),
      },
      {
        path: "/territories/:categoryId",
        meta: { auth: true, group: "territory" },
        name: "territories.detail",
        component: () => import("@/views/territories/TerritoryShow.vue"),
      },
      {
        path: "/territories/:categoryId/:itemId",
        meta: { auth: true, group: "territory" },
        name: "territories.place.detail",
        component: () => import("@/views/territories/PlaceShow.vue"),
      },
      /** @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */
      /*{
        path: "/units",
        meta: { auth: true, group: "units" },
        name: "item.units.list",
        component: () => import("@/views/territories/TerritoryList.vue"),
      },*/
      {
        path: "/units/monsters/",
        meta: { auth: true, group: "units" },
        name: "units.monsters.list",
        component: () => import("@/views/units/MonsterList.vue"),
      },
      {
        path: "/units/monsters/:itemId",
        meta: { auth: true, group: "units" },
        name: "units.monsters.detail",
        component: () => import("@/views/units/MonsterDetail.vue"),
      },
      {
        path: "/units/npc/",
        meta: { auth: true, group: "units" },
        name: "units.npc.list",
        component: () => import("@/views/units/NPCList.vue"),
      },
      {
        path: "/units/npc/:itemId",
        meta: { auth: true, group: "units" },
        name: "units.npc.detail",
        component: () => import("@/views/units/NPCDetail.vue"),
      },
      /** @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */

      {
        path: "/syncs/lang",
        name: "syncs.lang",
        meta: { auth: true },
        component: () => import("@/views/syncs/LangPage.vue"),
      },
      {
        path: "/syncs/area-mask",
        name: "syncs.area.mask",
        meta: { auth: true },
        component: () => import("@/views/syncs/AreaMaskPage.vue"),
      },
      {
        path: "/syncs/map-info",
        name: "syncs.map.info",
        meta: { auth: true },
        component: () => import("@/views/syncs/MapInfoPage.vue"),
      },
      {
        path: "/syncs/gui",
        name: "syncs.gui",
        meta: { auth: true },
        component: () => import("@/views/syncs/GuiPage.vue"),
      },
      {
        path: "/syncs/database",
        name: "syncs.database",
        meta: { auth: true },
        component: () => import("@/views/syncs/DatabasePage.vue"),
      },
      {
        path: "/syncs/items/resources",
        name: "syncs.items.resources",
        meta: { auth: true },
        component: () => import("@/views/syncs/ItemResourcePage.vue"),
      },
      {
        path: "/syncs/info.dat",
        name: "syncs.info.dat",
        meta: { auth: true },
        component: () => import("@/views/syncs/InfoDatPage.vue"),
      },
      {
        path: "/settings/properties/items",
        name: "syncs.info.dat",
        meta: { auth: true },
        component: () => import("@/views/settings/PropertiesPage.vue"),
      },
      {
        path: "/settings/collections",
        name: "syncs.info.dat",
        meta: { auth: true },
        component: () => import("@/views/settings/CollectionPage.vue"),
      },
      {
        path: "/library/quests/categories/:categoryId/items/:itemId",
        meta: { auth: true, group: "quests" },
        name: "quests.categories.quests.show",
        component: () => import("@/views/quests/QuestForm.vue"),
      },
      {
        path: "/library/quests/categories/:categoryId/:type",
        meta: { auth: true, group: "quests" },
        name: "quests.categories.form",
        component: () => import("@/views/quests/CategoryForm.vue"),
      },
      {
        path: "/library/quests/categories",
        meta: { auth: true, group: "quests" },
        name: "quests.categories",
        component: () => import("@/views/quests/CategoryList.vue"),
      },
      {
        path: "/library/quests/categories/search",
        meta: { auth: true, group: "quests" },
        name: "quests.search",
        component: () => import("@/views/quests/SearchForm.vue"),
      },
      {
        path: "/library/quests/categories/:categoryId",
        meta: { auth: true, group: "quests" },
        name: "quests.categories.detail",
        component: () => import("@/views/quests/CategoryList.vue"),
      },
      {
        path: "/test/editor",
        name: "test.editor",
        meta: { auth: true },
        component: () => import("@/views/test/EditorPage.vue"),
      },
    ],
  },

  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        meta: { auth: false },
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        meta: { auth: false },

        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        meta: { auth: false },

        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },

  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (r) => {
  const pinia = useRouteStore();
  // TODO Переделать на Pinia
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  store.commit("setActiveId", r.params);

  pinia.setRouteParams(r.params);

  const auth =
    typeof r.meta.auth !== "undefined" ? !(r.meta.auth === false) : true;

  if (auth) {
    if (!UserService.hasToken() || UserService.getUser().id === 0) {
      router.push({ name: "sign-in" }).then(() => {
        console.warn("not auth", UserService.getUser());
      });
    }
  }

  if (
    (r.name === Pages.LOGIN || r.name === Pages.REGISTER) &&
    UserService.hasToken() &&
    UserService.getUser().id > 0
  ) {
    await router.push({ name: "dashboard" });
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
