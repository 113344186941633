class UserService {
  private ID_TOKEN_KEY = "token" as string;
  private user = {
    id: 0,
  } as any;

  public setUser(user: any) {
    this.user = user;
  }

  public getUser(): any {
    return this.user;
  }

  public removeUser(): void {
    this.user = { id: 0 };
  }

  public getToken = (): string | null => {
    return window.localStorage.getItem(this.ID_TOKEN_KEY) ?? "";
  };

  public hasToken = (): boolean => {
    return (window.localStorage.getItem(this.ID_TOKEN_KEY) ?? "").length > 0;
  };

  public saveToken = (token: string): void => {
    window.localStorage.setItem(this.ID_TOKEN_KEY, token);
  };

  public destroyToken = (): void => {
    window.localStorage.removeItem(this.ID_TOKEN_KEY);
  };

  public logout = (): void => {
    this.destroyToken();
    this.removeUser();
  };
}

export default new UserService();
