/*import Axios, { AxiosInstance } from "axios";
import AuthService from "@/core/services/AuthService";

import router from "@/router";
import { App } from "vue";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";*/

import axios, { AxiosInstance } from "axios";
import { useToast } from "vue-toastification";
import { App } from "vue";
import VueAxios from "vue-axios";
import HTTPService from "./HTTPService";
import UserService from "@/core/services/UserService";
import router from "@/router";
import { Pages } from "@/store/enums/StoreEnums";
import ConfigModule from "@/store/modules/ConfigModule";
import { createStore } from "vuex";
import RequestService from "@/core/services/RequestService";

const store = createStore({ modules: { ConfigModule } });

const toast = useToast();

class ApiService {
  public static config = {
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "",
    },
  };

  public static vueInstance: App;

  public static async init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);

    ApiService.createRequestHandler();
    ApiService.events();

    await HTTPService.get("sanctum/csrf-cookie").then(async (res) => {
      if (typeof res !== "undefined") {
        store.commit("setStatusServer", true);
        await RequestService.me();
      } else store.commit("setStatusServer", false);
    });
  }

  private static _httpRequest: AxiosInstance = axios.create(
    ApiService.getConfig()
  );

  public static get httpRequest(): AxiosInstance {
    return ApiService._httpRequest;
  }

  public static set httpRequest(value: AxiosInstance) {
    ApiService._httpRequest = value;
  }

  public static events() {
    const currentRoute = router.currentRoute.value;

    ApiService._httpRequest.interceptors.response.use(
      (res) => {
        return res;
      },
      (err) => {
        if (typeof err.response === "undefined") {
          /*
          toast.error("Ошибка работы сервера", {
            timeout: 5000,
          });
          */
        } else {
          // Если вернуть err, то сообщение сразу перебросится в then
          switch (err.response.status) {
            case 401:
              if (
                currentRoute.name === Pages.LOGIN ||
                currentRoute.name === Pages.REGISTER
              ) {
                err.data = {
                  type: "error",
                  status: err.response.status,
                  need_redirect: false,
                  message: "Неправильный логин или пароль",
                };
                return err;
              } else {
                UserService.logout();
                router.push({ name: Pages.LOGIN }).then(() => {
                  toast.error("Ошибка сессии", {
                    timeout: 2000,
                  });
                });
                return {};
              }

            case 404:
              err.data = {
                type: "error",
                status: err.response.status,
                need_redirect: true,
                message: "Ошибка. Страница не найдена",
              };
              return err;

            case 422:
              err.data = {
                type: "error",
                status: err.response.status,
                need_redirect: true,
                message: err.response.data.messages,
              };
              return err;

            default:
              console.error("Ошибка сервера:", err.response);

              router.push("/500").then(() => {
                toast.error("Ошибка в работе сервера", {
                  timeout: 2000,
                });
              });
              break;
          }
        }
      }
    );
  }

  public static createRequestHandler() {
    ApiService._httpRequest = axios.create(ApiService.getConfig());
  }

  private static getConfig() {
    if (UserService.hasToken()) {
      ApiService.config.headers.Authorization = `Bearer ${UserService.getToken()}`;
    }
    return ApiService.config;
  }
}

export default ApiService;
