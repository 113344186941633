import UserService from "@/core/services/UserService";
import HTTPService from "@/core/services/HTTPService";

class RequestService {
  public async me() {
    if (UserService.hasToken()) {
      await HTTPService.get("api/user").then((user) => {
        if (
          typeof user.data.type !== "undefined" &&
          user.data.type === "error"
        ) {
          UserService.destroyToken();
        } else {
          UserService.setUser(user.data);
        }
      });
    }
  }
}
export default new RequestService();
