import { defineStore } from "pinia";
import { ref } from "vue";

export const useNoticeStore = defineStore("notice", () => {
  const id = ref(0);
  const errors = ref({});

  const setError = (error: any) => {
    errors.value = { ...error };
  };

  const getErrors = () => {
    return errors.value;
  };

  const clearErrors = () => {
    errors.value = {};
  };

  return { id, setError, getErrors, clearErrors, errors };
});
