import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/core/plugins/i18n";
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import Toast, { PluginOptions } from "vue-toastification";

import "@/core/plugins/prismjs";
import "bootstrap";
import "@/assets/css/main.css";
import "@/assets/css/report.css";
import "@imengyu/vue3-context-menu/lib/vue3-context-menu.css";
import "vue-toastification/dist/index.css";

const pinia = createPinia();
const app = createApp(App);
app.use(pinia);

// app.use(ElementPlus, {});
(async () => {
  document.addEventListener("DOMContentLoaded", async () => {
    await ApiService.init(app).then((user) => {
      initApexCharts(app);
      initInlineSvg(app);
      initVeeValidate();
      app.use(i18n);

      app.use(Toast, {} as PluginOptions);

      app.use(store);
      app.use(router);

      app.mount("#app");
    });
    // the rest of the code
  });
})();
