export default class PublicError {
  private errors: Record<string, string[]>;

  constructor(errors: Record<string, string[]>) {
    this.errors = errors || {};
  }

  public hasError() {
    return Object.keys(this.errors).length > 0;
  }

  public getErrorText(): string {
    if (this.hasError()) {
      return Object.entries(this.errors)
        .map(([key, messages]) => {
          return typeof messages === "string" ? messages : messages.join("\n");
        })
        .join("\n\n");
    }
    return "";
  }
}
