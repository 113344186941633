import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted, nextTick, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useNoticeStore } from "@/store/pinia/NoticeModule";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { Mutations, Pages } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toastification";
import PublicError from "@/services/PublicError";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const store = useStore();
const notice = useNoticeStore();
const toast = useToast();

notice.$onAction(({ name: name, after: after }) => {
  after(() => {
    if (name === "setError") {
      const handle = new PublicError(notice.getErrors());

      if (handle.hasError()) {
        toast.error(handle.getErrorText(), {
          timeout: 2000,
        });
      }
    }
  });
});

// Выполняется перед монтированием компонента
onBeforeMount(() => {
  /**
   * Здесь можно выполнить логику для инициализации конфигурации
   * макета с использованием данных из localStorage.
   */
  // searchStore.refreshHistorySearch();
});

// Выполняется после монтирования компонента
onMounted(() => {
  // Коммит для переопределения конфигурации макета
  store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

  // Удаление класса "page-loading" из body после загрузки страницы
  document.body.classList.remove("page-loading");

  nextTick(() => {
    // Инициализация компонентов после удаления класса "page-loading"
    initializeComponents();
  });
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view))
}
}

})