import { defineStore } from "pinia";
import { ref } from "vue";

export const useRouteStore = defineStore("route", () => {
  const id = ref(0);
  const itemId = ref(0);
  const type = ref("default" as string);

  const setId = (params: any) => {
    id.value =
      typeof params["categoryId"] === "undefined"
        ? 0
        : parseInt(params["categoryId"]);
  };

  const setItemId = (params: any) => {
    itemId.value =
      typeof params["itemId"] === "undefined" ? 0 : parseInt(params["itemId"]);
  };

  const setTypePage = (params: any) => {
    type.value =
      typeof params["type"] === "undefined" ? "default" : params["type"];
  };

  const setRouteParams = (params: any) => {
    setItemId(params);
    setId(params);
    setTypePage(params);
  };

  return { id, itemId, type, setId, setItemId, setTypePage, setRouteParams };
});
