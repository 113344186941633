<template>
  <router-view />
</template>

<script setup lang="ts">
import { onMounted, nextTick, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useNoticeStore } from "@/store/pinia/NoticeModule";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { Mutations, Pages } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toastification";
import PublicError from "@/services/PublicError";

const store = useStore();
const notice = useNoticeStore();
const toast = useToast();

notice.$onAction(({ name: name, after: after }) => {
  after(() => {
    if (name === "setError") {
      const handle = new PublicError(notice.getErrors());

      if (handle.hasError()) {
        toast.error(handle.getErrorText(), {
          timeout: 2000,
        });
      }
    }
  });
});

// Выполняется перед монтированием компонента
onBeforeMount(() => {
  /**
   * Здесь можно выполнить логику для инициализации конфигурации
   * макета с использованием данных из localStorage.
   */
  // searchStore.refreshHistorySearch();
});

// Выполняется после монтирования компонента
onMounted(() => {
  // Коммит для переопределения конфигурации макета
  store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

  // Удаление класса "page-loading" из body после загрузки страницы
  document.body.classList.remove("page-loading");

  nextTick(() => {
    // Инициализация компонентов после удаления класса "page-loading"
    initializeComponents();
  });
});
</script>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~quill/dist/quill.snow.css";
@import "~animate.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~element-plus/dist/index.css";

// Основной стиль демо
@import "assets/sass/plugins";
@import "assets/sass/style";

// Стиль демо для темной темы
@import "assets/sass/plugins.dark";
@import "assets/sass/style.dark";
@import "assets/sass/element-ui.dark";

// Стиль для RTL-версии
//@import "assets/css/style.rtl.css";

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active {
  color: #fff;
}
</style>
