import HTTPService from "@/core/services/HTTPService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { useToast } from "vue-toastification";
import UserService from "@/core/services/UserService";
import ApiService from "@/core/services/ApiService";
const toast = useToast();

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
}

export interface UserAuthInfo {
  errors: object;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [] as Array<any>;
  user = {} as User;
  isAuthenticated = true; // !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  public test;
  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error: any) {
    this.errors.push(error);
  }

  @Mutation
  [Mutations.SET_AUTH](data: any) {
    /*
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
    */
    // JwtService.saveToken(user.authorization.token);
    // ApiService.createRequestHandler();
    // AuthService.me();
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
    // TODO write in localstorage
  }

  get [Mutations.GET_USER]() {
    return this.user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    // JwtService.destroyToken();
  }

  @Action
  async [Actions.LOGIN](credentials: object) {
    try {
      const { data } = await HTTPService.post("api/login", credentials);
      console.log("login data:", data);
      if (data.type === "success") {
        UserService.saveToken(data.token);
        UserService.setUser(data.user);
        ApiService.createRequestHandler();
      } else {
        this.context.commit(Mutations.SET_ERROR, data.message);
      }
      // this.context.commit(Mutations.SET_AUTH, data);
    } catch (error) {
      toast.error("Непредвиденная ошибка сервера!", {
        timeout: 2000,
      });
    }
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }
}
